$mobile-min-width: 320px; // Samsung Galaxy S
$mobile-max-width: 428px; // Apple iPhone 12 Pro Max
$tablet-min-width: 600px; // Samsung Galaxy Tab 2 (7")
$tablet-max-width: 1024px; // Apple iPad Pro

#references {
  margin-bottom: 149px;
}

#referencePictures {
  color: white;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  max-width: 68%;
  margin: auto;
}

.referenceLogo {
  width:250px;
  height:170px;
}

@media only screen and (max-width:$tablet-max-width) {
  #referencePictures {
    grid-template-columns: auto;
    justify-content: center;
  }
}

@media only screen and (max-width:$mobile-max-width) {
  #referencePictures {
    grid-template-columns: 1fr;
    justify-content: center;
    img {
      margin: auto;
    }
  }
}
