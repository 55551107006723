$mobile-min-width: 320px; // Samsung Galaxy S
$mobile-max-width: 428px; // Apple iPhone 12 Pro Max
$tablet-min-width: 600px; // Samsung Galaxy Tab 2 (7")
$tablet-max-width: 1024px; // Apple iPad Pro

#services {
    color: white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 120px;
    grid-row-gap: 91px;
    margin: auto;
    max-width: 741px;
 
    .service {
        max-width: 300px;
        margin: auto;
        img{
            width:250px;
            height:181px;
            margin:auto;
        }
    
        p {
            color: "#93C1D8";
        }
    }
    

}

#letsTalk {
    width:170.62px;
    background-repeat: no-repeat;
    text-align: center;
    margin:auto;
    margin-bottom:163px;

    a {
        font-family: "Khand-SemiBold";
    }

    a:hover{
        color:#00070a;
    }
}

@media only screen and (max-width: $tablet-max-width) {
    #services {
        grid-row-gap: 100px;
        grid-template-columns: auto;
    }

}

@media only screen and (max-width: $mobile-max-width) {
    #services {
        grid-row-gap: 100px;
        grid-template-columns: auto;

        
    }

    #letsTalk {
        margin-bottom:50px;
    }
}