$mobile-min-width: 320px; // Samsung Galaxy S
$mobile-max-width: 428px; // Apple iPhone 12 Pro Max
$tablet-min-width: 600px; // Samsung Galaxy Tab 2 (7")
$tablet-max-width: 1024px; // Apple iPad Pro

#languageSelector {
    margin-left: 5%;
    width:150px;
    height:0px;
    button {
        
        background-color: #93C1D8;
        font-family: "Inter";
        font-weight:bold;
        border:none;
        margin:20px 5px 5px 5px;
        width:50px;
    }

    button:hover {
        background-color: rgb(65, 206, 253);
    }
}

@media only screen and (max-width: $mobile-max-width) and (min-width: $mobile-min-width) {

}