$mobile-min-width: 320px; // Samsung Galaxy S
$mobile-max-width: 428px; // Apple iPhone 12 Pro Max
$tablet-min-width: 600px; // Samsung Galaxy Tab 2 (7")
$tablet-max-width: 1024px; // Apple iPad Pro

#header {
    color: white;
    max-height: 720px;
    height: 695.34px;
    left: 0px;
    top: 0px;

    width: 100%;

    .introWrapper {
        max-width: 90%;
        margin: auto;
        text-align: center;
  
        padding-top:5%;
        h1 {
            font-size: 3rem;
            line-height: 50px;
            margin-bottom: 23px;
            max-width: 75%;
            margin: auto;
            text-align: center;

        }

        p {
            padding-top: 5%;
            font-size: 1rem;
            max-width: 50%;
          
            margin: auto;
        }

    }

}

#pwntekTextLogo {
    margin: auto;
}

.logo {
    padding-top: 100px;
    padding-bottom: 100px;
    margin: auto;
    width: auto;
    height: auto;
    text-align: center;
}

// Tablet
@media only screen and (max-width: $tablet-max-width) {}

// Phone
@media only screen and (max-width: $mobile-max-width) {

    #header {
        margin-bottom: 100px;

        .introWrapper {
            h1 {
                max-width: 100%;
            }

            p {
                max-width: 90%;
            }

        }
    }

    #logo {
        margin: 100px 0 110px 0;
    }
}