$mobile-min-width: 320px; // Samsung Galaxy S
$mobile-max-width: 428px; // Apple iPhone 12 Pro Max
$tablet-min-width: 600px; // Samsung Galaxy Tab 2 (7")
$tablet-max-width: 1024px; // Apple iPad Pro

#contactWrapper {
    margin-bottom: 140px;

}

a {
    font-family: "Inter";
}

#contact {
    color: white;
    text-align: right;
    line-height: 0.5rem;
    display: grid;
    grid-template-columns: 30% 40%;
    grid-column-gap: 10%;
    grid-row-gap: 5vh;
    margin: auto;
}

@media only screen and (max-width: $tablet-max-width) {
    #contact {
        grid-template-columns: auto;
        justify-self: center;
        text-align: center;
    }
}


@media only screen and (max-width: $mobile-max-width) {
    #contact {
        width: 90%;
        grid-template-columns: 1fr;
        grid-column-gap: 0px;
        text-align: left;
        justify-self: center;
    }
}
