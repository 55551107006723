$mobile-min-width: 320px; // Samsung Galaxy S
$mobile-max-width: 428px; // Apple iPhone 12 Pro Max
$tablet-min-width: 600px; // Samsung Galaxy Tab 2 (7")
$tablet-max-width: 1024px; // Apple iPad Pro

.sectionGrid {
    grid-template-columns: "1fr 1fr 1fr";
    grid-template-rows:"1";
    margin-bottom:"128px";
}

@media only screen and (max-width: $mobile-max-width) and (min-width: $mobile-min-width) {

}