@font-face {
    font-family: "Khand-Light";
    src: url("./fonts/khand/Khand-Light.ttf");
}

@font-face {
    font-family: "Khand-Bold";
    src: url("./fonts/khand/Khand-Bold.ttf");
}

@font-face {
    font-family: "Khand-SemiBold";
    src: url("./fonts/khand/Khand-SemiBold.ttf");
}

@font-face {
    font-family: "Inter";
    src: url("./fonts/inter/Inter-VariableFont_slnt,wght.ttf");
}

$mobile-min-width: 320px; // Samsung Galaxy S
$mobile-max-width: 428px; // Apple iPhone 12 Pro Max
$tablet-min-width: 600px; // Samsung Galaxy Tab 2 (7")
$tablet-max-width: 1024px; // Apple iPad Pro

html {
    font-size: 1rem;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

a {
    color: white;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    color: fade-in($color: rgb(65, 206, 253), $amount: 0.5);
}


body {
    font-family: "Khand-Light";
    background-color: #00070a;
    color: white;
}

.App {
    max-width: 1280px;
    margin: auto;
}

.wrapper {
    margin: auto;
}

p {
    font-family: "Inter";
}

.h1 {
    font-weight: bold;
}



@media only screen and (max-width:$tablet-max-width) {
    .wrapper {
        width: 90%;
        margin: auto;
    }

    .App {
        max-width: $tablet-max-width;
    }

    #logo {
        max-width: $tablet-max-width;
    }
}

@media only screen and (max-width: $mobile-max-width) {
    .App {
        max-width: $mobile-max-width;
    }

    #logo {
        max-width: $mobile-max-width;
    }

    .wrapper {
        width: 90%;
        margin: auto;
    }
}