$mobile-min-width: 320px; // Samsung Galaxy S
$mobile-max-width: 428px; // Apple iPhone 12 Pro Max
$tablet-min-width: 600px; // Samsung Galaxy Tab 2 (7")
$tablet-max-width: 1024px; // Apple iPad Pro

#footer {
    color: #598393;
    background-color: #001928;
    text-align: left;
    padding-top: 62px;

 
    clear: both;

    .wrapper {
        justify-self: center;
        justify-items: center;
        display: grid;
        row-gap: 10%;
        grid-template-columns: 1fr 1fr;
        width:100%;
        line-height: 0.5rem;
        margin-bottom: 5%;
    }

    #address {
        margin: auto;
    }

    #contactInfo {
        margin: auto;
    }
}

#copyright {
    margin: auto;
    text-align: center;
    padding-bottom: 10px;
}


@media only screen and (max-width: $mobile-max-width) and (min-width: $mobile-min-width) {
    #footer {
        .wrapper {
            grid-template-columns: 1fr;
            justify-content: center;
            margin-bottom: 5%;
        }

        #address {
   
            width:55%;
            margin: auto;

        }
        #contactInfo {
            width:55%;
        }
    }
}